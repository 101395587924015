/*
 * NotFound css File
*/

#notfound .notfound {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
.notfound .notfound-404 {
    position: relative;
    height: 180px;
}
.notfound h2 {
    font-family: 'Roboto', sans-serif;
    font-size: 22px;
    font-weight: 400;
    text-transform: uppercase;
    color: #151515;
    margin-top: 0px;
    margin-bottom: 25px;
}
.notfound .notfound-404 h2 {
    font-family: 'Roboto', sans-serif;
    position: absolute;
    left: 50%;
    top: 60%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-size: 165px;
    font-weight: 700;
    margin: 0px;
    color: #0085ff;
    text-transform: uppercase;
}
.notfound .notfound-404 h2>span {
    color: red;
}