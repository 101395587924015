/*
 * Questionnaire css File
*/
.slider {
    width: 100% !important;
  }

.btn-app,.btn-app:hover
{
    background-color:#0085FF;
    color:#fff;
    font-weight: bold;
    border-radius: 0px;
    z-index: 9 !important;
}
.slider-handle,.slider-tick.in-selection,.slider-selection.tick-slider-selection
{
    background-color:#A3CEFE;
    background-image:linear-gradient(to bottom, #A3CEFE 0%, #0085dd 100%)
}