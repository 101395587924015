.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.app-title {
  color:#0085ff;
}
footer{
  z-index: 0 !important;
}
.border-short{
  border-bottom:1px dotted #ccc;
  padding-bottom: 2px;
}
